

import React, { useEffect } from 'react';
import banar from '../../Assets/Home/banara.png';
import CountUp from 'react-countup';
import AOS from 'aos';
import 'aos/dist/aos.css';

const HomeBanner = () => {

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <React.Fragment>
            <div
                className='md:h-screen h-[45rem] relative'
                style={{
                    backgroundImage: `url(${banar})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "left",
                }}
            >
                <div className='absolute left-8 md:left-16 top-10 md:top-20 space-y-4 md:space-y-7 w-[80%] md:w-[50%] mr-5'>
                    <h3 className='text-[#2D8014] text-xl md:text-2xl' data-aos="fade-right">Welcome to <strong>Deb Online Shopping Store </strong> </h3>
                    <h1 className='text-4xl md:text-6xl tracking-wide' data-aos="fade-right">
                        We Provide Delivery<br />
                        Within 30 Min
                    </h1>
                    <p className='text-lg md:text-xl tracking-wide' data-aos="fade-right">
                        We offer exceptional delivery service, ensuring your orders arrive within 30 minutes directly
                    </p>
                    <div className='flex flex-col md:flex-row md:space-x-8 space-y-4 md:space-y-0' data-aos="fade-up">
                        <span className='text-white bg-[#2D8014] px-4 md:px-10 py-2 md:py-4 rounded-full text-center'>
                            Get Started
                        </span>
                        <span className='bg-white text-[#2D8014] px-4 md:px-10 py-2 md:py-4 rounded-full border-2 border-[#2D8014] text-center'>
                            Order Step
                        </span>
                    </div>
                    <div className='flex space-x-8 md:space-x-14' data-aos="fade-up">
                        <div className='flex items-center justify-center flex-col space-y-2 md:space-y-5'>
                            <h1 className='text-4xl md:text-5xl text-[#2D8014]'>
                                <CountUp end={40} duration={2} />
                                <span>k</span>
                            </h1>
                            <p className='text-base md:text-lg'>Satisfied Customer</p>
                        </div>
                        <div className='flex items-center justify-center flex-col space-y-2 md:space-y-5'>
                            <h1 className='text-4xl md:text-5xl text-[#2D8014]'>
                                <CountUp end={45} duration={2} />
                                <span>k</span>
                            </h1>
                            <p className='text-base md:text-lg'>Food Delivered</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default HomeBanner;
