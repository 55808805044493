
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Service from '../../Assets/Home/BestService.png';
import pay from '../../Assets/Aboutus/paystore.png';

const BestService = () => {

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <React.Fragment>
      <div className='flex flex-col md:flex-row mt-8 md:px-16 px-6 w-[100%]'>
        <div className='w-full md:w-[50%]' data-aos="fade-right">
          <div className='space-y-5'>
            <h3 className='text-start text-[#2D8014] text-lg md:text-xl tracking-wide'>Mobile Apps</h3>
            <h1 className='text-4xl md:text-6xl tracking-wide'>Best Service to fulfil your expectations.</h1>
            <p className='tracking-wide'>Our Best Service ensures your expectations are met with top-quality solutions, personalized support, and reliable performance, delivering satisfaction and exceeding your standards every time satisfaction and exceeding.</p>
            <h1 className='text-2xl md:text-3xl tracking-wide'>Download Our Mobile App</h1>
            <a href="https://play.google.com/store/apps/details?id=com.seetal" target="_blank" rel="noopener noreferrer">
              <img src={pay} alt='img' className='w-[50%] md:w-[30%]' />
            </a>
          </div>
        </div>
        <div className='w-full md:w-[50%] mt-6 md:mt-0 flex items-center justify-center' data-aos="fade-left">
          <img src={Service} alt='BestService' />
        </div>
      </div>
    </React.Fragment>
  );
}

export default BestService;
