

import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import left from '../../Assets/Contact/left.png';
import right from '../../Assets/Contact/right.png';

const SendUsMessage = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Duration of animations
            once: true, // Whether animation should happen only once
        });
    }, []);

    return (
        <React.Fragment>
            <h3 className='text-center text-3xl mt-10 text-[#2D8014]' data-aos="fade-up">Contact</h3>
            <h1 className='text-center text-4xl sm:text-6xl mt-5' data-aos="fade-up" data-aos-delay="200">Get In Touch With Us</h1>
            <p className='text-center mt-5 text-sm sm:text-base px-6 md:px-16' data-aos="fade-up" data-aos-delay="400">
                Get in touch with us for any inquiries or support. Our team is ready to assist you with personalized attention, answering your <br className='hidden sm:block' /> questions and addressing your concerns to ensure a smooth and satisfactory experience.
            </p>
            <p className='text-[#2D8014] text-center mt-5 text-sm sm:text-base px-6 md:px-16' data-aos="fade-up" data-aos-delay="400">
                <strong>Deb Online Shopping Store</strong> <br />
                Plot No-1187, Taradapada, Purohitpur,
                Jagatsinghapur, Odisha, 754294
            </p>
            <div className="mt-12 w-full sm:w-[90%] md:w-[90%] lg:w-[70%] mx-auto relative">
                <div className="bg-white shadow-md rounded-lg px-6 sm:px-8 py-10" data-aos="fade-up" data-aos-delay="600">
                    <h3 className="text-center text-2xl sm:text-[45px] text-green-600 mb-6 font-poppins font-medium leading-[72px] tracking-[0.02em]">
                        Send Us a Message
                    </h3>

                    <form className="space-y-6 px-7">
                        <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
                            <div className="w-full sm:w-1/2" data-aos="fade-right">
                                <label
                                    htmlFor="first-name"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    name="first-name"
                                    id="first-name"
                                    placeholder="Enter your first name"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                />
                            </div>

                            <div className="w-full sm:w-1/2" data-aos="fade-left">
                                <label
                                    htmlFor="last-name"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    name="last-name"
                                    id="last-name"
                                    placeholder="Enter your last name"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div data-aos="fade-up">
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Email
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Enter your email address"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            />
                        </div>

                        <div data-aos="fade-up" data-aos-delay="200">
                            <label
                                htmlFor="message"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Message
                            </label>
                            <textarea
                                name="message"
                                id="message"
                                placeholder="Write your message here"
                                rows="4"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            ></textarea>
                        </div>

                        <div className='flex items-center justify-center'>
                            <button
                                type="submit"
                                className="w-full sm:w-[50%] lg:w-[40%] bg-green-600 text-white py-2 px-2 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                data-aos="fade-up"
                                data-aos-delay="400"
                            >
                                Send Message
                            </button>
                        </div>
                    </form>
                </div>
                <img src={left} alt='left' className='absolute top-36 left-0 hidden md:block' data-aos="fade-right" />
                <img src={right} alt='right' className='absolute top-36 right-0 hidden md:block' data-aos="fade-left" />
            </div>
        </React.Fragment>
    );
}

export default SendUsMessage;
