import React from 'react';
import logo from '../../Assets/logos/logo.svg';
import play from '../../Assets/Aboutus/paystore.png';
import apple from '../../Assets/Aboutus/applestore.png';
import { FaFacebook, FaTwitter } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { BsYoutube } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const navigate = useNavigate();

    return (
        <React.Fragment>
            {/* Main Footer Section */}
            <div className="flex flex-col lg:flex-row lg:space-x-16 px-4 md:px-8 lg:px-16 bg-[#191C1F] text-white py-12 space-y-8 lg:space-y-0">
                {/* Logo & Social Links */}
                <div className="w-full lg:w-[25%] flex flex-col lg:items-start lg:text-left gap-4">
                    <img src={logo} alt="Logo" className="w-[40%] mb-4" />
                    <p className="text-sm text-[#929FA5]">
                        Commercial Production Started And The Company Incorporated On February 22, 2003.
                    </p>
                    <div className="flex space-x-4 mt-4">
                        <FaFacebook className="text-gray-400 hover:text-white" />
                        <FaTwitter className="text-gray-400 hover:text-white" />
                        <FiInstagram className="text-gray-400 hover:text-white" />
                        <BsYoutube className="text-gray-400 hover:text-white" />
                    </div>
                </div>

                {/* Quick Links */}
                <div className="w-full lg:w-[25%] flex flex-col gap-4">
                    <h4 className="text-lg mb-2">Quick Links</h4>
                    <div className="space-y-2 text-[#929FA5]">
                        <div onClick={() => { navigate('/'); window.scrollTo(300, 0); }} className="cursor-pointer hover:text-white">Home</div>
                        <div onClick={() => { navigate('/about'); window.scrollTo(300, 0); }} className="cursor-pointer hover:text-white">About Us</div>
                        <div onClick={() => { navigate('/services'); window.scrollTo(300, 0); }} className="cursor-pointer hover:text-white">Services</div>
                        <div onClick={() => { navigate('/contact'); window.scrollTo(300, 0); }} className="cursor-pointer hover:text-white">Contact</div>
                    </div>
                </div>

                {/* Policies */}
                <div className="w-full lg:w-[25%] flex flex-col gap-4">
                    <h4 className="text-lg mb-2">Policies</h4>
                    <div className="space-y-2 text-[#929FA5]">
                        <div onClick={() => { navigate('/terms'); window.scrollTo(300, 0); }} className="cursor-pointer hover:text-white">Terms & Conditions</div>
                        <div onClick={() => { navigate('/refund-cancellation'); window.scrollTo(300, 0); }} className="cursor-pointer hover:text-white">Refund & Cancellation Policy</div>
                        <div onClick={() => { navigate('/privacy-pricing'); window.scrollTo(300, 0); }} className="cursor-pointer hover:text-white">Privacy & Pricing Policy</div>
                    </div>
                </div>

                {/* Contact Info & App Store Links */}
                <div className="w-full lg:w-[25%] flex flex-col gap-4">
                    <h4 className="text-lg mb-2">Contact Info</h4>
                    <div className="text-[#929FA5]">

                        <p>
                            Deb Online Shopping Store <br />
                            Plot No-1187, Taradapada, Purohitpur,
                            Jagatsinghapur, Odisha, 754294
                        </p>
                        <p>+91 81445 70016</p>
                        <p>debdebasmitagrandbazar@gmail.com</p>
                    </div>
                    <div className="mt-4 flex space-x-4">
                        <a href="https://play.google.com/store/apps/details?id=com.seetal" target="_blank" rel="noopener noreferrer">
                            <img src={play} alt="Google Play" className="w-[100%] md:w-[80%]" />
                        </a>
                        <img src={apple} alt="Apple Store" className="w-[50%] md:w-[40%]" />
                    </div>
                </div>
            </div>

            {/* Bottom Footer Section */}
            <footer className="bg-gray-800 text-white px-5 py-4">
                <div className="container mx-auto">
                    <div className="flex flex-col md:flex-row justify-between items-center text-center md:text-left">
                        <div className="mb-4 md:mb-0">

                        </div>
                        <div className="text-sm">
                            Design & Developed by
                            <a href="https://www.webbocket.com/" className="ml-1 underline hover:text-gray-400">Web-Bocket</a>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
