import React from 'react';
import SimpleBanner from '../Common/SimpleBanner';
import Aboutbanar from '../../Assets/common/banar.jpeg';

const Terms = () => {
  return (
    <React.Fragment>
      <SimpleBanner
        image={Aboutbanar}
        name="Terms & Conditions"
        path="Home / Terms & Conditions"
      />
      <div className="bg-gray-100 py-12">
        <div className="container mx-auto px-4 md:px-8 lg:px-16">
          <div className="bg-white shadow-lg rounded-lg p-6 lg:p-10">
            <div className="space-y-8 text-gray-700">
              <section>
                <h2 className="text-xl font-semibold mb-4">1. Introduction</h2>
                <p>Welcome to <strong>Deb Online Shopping Store  An Unit of SEETAL AGRO INPUTS PRIVATE LIMITED</strong>. By using our website or placing an order with us, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before using our services.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-4">2. Eligibility</h2>
                <p>To place an order, you must be at least 18 years old and capable of entering into legally binding agreements. By using our website, you represent that you meet this requirement.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-4">3. Account Registration</h2>
                <p>When placing an order, you may be required to create an account. You are responsible for maintaining the confidentiality of your account details, including your password. You agree to provide accurate and complete information during registration.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-4">4. Order Placement</h2>
                <p>Once an order is placed, it cannot be modified or canceled unless approved by us. Ensure all details are correct before submitting your order. Orders will be processed and delivered based on product availability. We will notify you in case any items are unavailable or if there is any issue with the order.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-4">5. Pricing & Payment</h2>
                <p>All prices are listed in <strong>Indian rupee</strong> and are subject to change without notice. You agree to pay for the full amount of your order, including applicable taxes and delivery fees. Payment can be made using the accepted methods on our website.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-4">6. Delivery</h2>
                <p>We offer in-home delivery for all orders. Delivery times are estimates and may vary based on factors such as weather, traffic, and location. If no one is available to accept the delivery, it may be returned, and re-delivery charges could apply.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-4">7. Returns & Refunds</h2>
                <p>Refer to our <strong>Refund & Cancellation Policy</strong> for detailed information regarding returns, cancellations, and refunds.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-4">8. Prohibited Uses</h2>
                <p>You agree not to use our website for any unlawful purposes, including but not limited to:</p>
                <ul className="list-disc list-inside">
                  <li>Disrupting our services or website</li>
                  <li>Violating the rights of others</li>
                  <li>Falsifying or misrepresenting your identity</li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-4">9. Changes to Terms</h2>
                <p>We reserve the right to update or modify these Terms & Conditions at any time. Any changes will be posted on our website, and continued use of our services implies acceptance of the updated terms.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-4">10. Governing Law</h2>
                <p>
                  These terms are governed by the laws of <strong>India</strong>, and any disputes will be handled in the courts of <strong>Cuttack, Odisha</strong>.
                </p>
              </section>

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Terms;
