

import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const StoryAboutUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <React.Fragment>
      <div
        className="p-6 md:p-16 text-center bg-[#0000000D]"
        data-aos="fade-up"
      >
        <p className="text-4xl md:text-[60px] font-semibold mb-7 px-6 md:px-16">
          Story About Us
        </p>
        <p className="text-base md:text-lg mb-4 px-6 md:px-16"><strong>Deb Online Shopping Store </strong></p>
        <p
          className="text-gray-800 text-base md:text-xl font-light italic leading-relaxed px-6 md:px-16"
          data-aos="fade-up"
        >
          Our story is built on a passion for excellence and customer satisfaction. With years of experience, we’ve grown into a trusted brand, delivering quality products, innovative services, and personalized solutions tailored to meet your unique needs.
        </p>
      </div>
    </React.Fragment>
  );
}

export default StoryAboutUs;
